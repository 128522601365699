import { FooterColumnStoryblok } from '@/shared/types/storyblok/storyblok-types';

import RichText from '@/components/RichText/RichText';
import { useBreakpoints } from '@/shared/providers/Device';
import { StoryblokComponent } from '@storyblok/react';
import styles from '../Footer.module.scss';

type FooterColumnProps = {
    blok: FooterColumnStoryblok;
};

const FooterColumn = (blok: FooterColumnProps) => {
    const { isExtraLarge, isLarge } = useBreakpoints();
    const isMobile = !isExtraLarge && !isLarge;

    return (
        <div
            className={styles.column}
            style={{
                order: isMobile ? blok.blok?.orderMobile : undefined,
                minWidth: isMobile ? blok.blok?.minWidthMobile + '%' : undefined,
                paddingBottom: isMobile ? '8px' : undefined,
            }}
            key={blok.blok._uid}
        >
            {blok.blok?.content?.map(nestedBlok => {
                if (nestedBlok.component === 'richText' && nestedBlok.richText) {
                    return <RichText key={nestedBlok._uid} document={nestedBlok.richText} />;
                } else
                    return (
                        <StoryblokComponent
                            key={nestedBlok._uid}
                            blok={nestedBlok.component === 'richText' ? nestedBlok.richText : nestedBlok}
                        />
                    );
            })}
        </div>
    );
};

export default FooterColumn;
